

.contact-main {
    width: 100%;
    margin-top: 200px;
}

.contact-main-wrapper {
    width: 70%;
    margin: 0 auto;
    display: flex;
}

.contact-left {
    width: 50%;
    padding: 20px;
}

.contact-right {
    width: 50%;
    padding: 20px;
}

.hisa-contact {
    width: 100%;
}

.contact-header {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(2rem, 1.7345rem + 1.4159vw, 4rem);
}

.input-label {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(0.75rem, 0.5897rem + 0.2564vw, 1rem);
        margin-left: 12px;
    transform: translateY(20px);
    position: absolute;
    transition: transform 0.3s ease, font-size 0.3s ease;
}

.input-label.active {
    transform: translateY(0px);
    font-size: clamp(0.625rem, 0.5449rem + 0.1282vw, 0.75rem);

}

.input-label.is-populated {
    transform: translateY(0px);
    font-size: 12px;

}

.input-textbox {
    width: 100%;
    height: 40px;
    background-color: black;
    color: #EEEEEE;
    border: none;
    border-bottom: 1px solid #8E8CF2;
    padding: 12px;
    outline: none;
    margin-top: 12px;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
}

.input-textbox2 {
    width: 100%;
    height: 80px;
    background-color: black;
    color: #EEEEEE;
    border: none;
    border-bottom: 1px solid #8E8CF2;
    padding: 12px;
    outline: none;
    margin-top: 12px;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
}

.input-row {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
}

.input-row2 {
    display: flex;
    width: 100%;
    margin-bottom: 0px;
}

.input-container {
    width: 50%;
    padding-right: 60PX;
    display: flex;
    flex-direction: column;
    position: relative;
}

.input-container2 {
    width: 100%;
    padding-right: 60PX;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
}

.info-div {
    width: 60%;
    padding: 40px;
    background-color: white;
    transform: translateY(-3px);
    display: flex;
    flex-direction: column;
    
}

.contact-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.info-icon-wrapper {
    width: 40px;
    height: 40px;
}

.info-img {
    width: 100%;
}

.info-contact-paragraph {

    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #042C53;
    font-size: clamp(0.75rem, 0.5897rem + 0.2564vw, 1rem);
        margin-left: 22px;

}

.contact-map{
    margin-top: 80px;
    transform: translateY(4px);
}

@media screen and (max-width: 1400px) {
    .contact-main-wrapper{
        width: 80%;
    }

    .input-row{
        flex-direction: column;
        margin-bottom: 0;
    }

    .input-container{
        width: 100%;
    }

    .input-container2{
        width: 100%;
        padding-right: 0px;
    }

    .contact-right{
        margin-left: 40px;
    }

    .input-container{
        padding-right: 0px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1000px) {

    .contact-main {
        width: 100%;
        margin-top: 121px;
    }

    .contact-main-wrapper{
        width: 90%;
        flex-direction: column;
    }

    .contact-right{
        margin-left: 0px;
        padding: 0px;
        margin-top: 64px;

        width: 100%;
    }

    .contact-left{
        
        padding: 0px;
        width: 100%;
    }

    .input-container{
        padding-right: 0px;
        margin-bottom: 20px;
    }

    .input-container2{
        padding-right: 0px;
    }

    .input-textbox{
        padding: 0px;
        padding-top: 12px;
        padding-bottom: 12px;

    }

    .input-textbox2{
        padding: 0px;
        padding-top: 12px;
        padding-bottom: 12px;

    }

    .button-row{
        margin-right: 0px;
    }

    .info-div{
        width: 100%;
        padding: 0px;
        padding-top: 12px;
        padding-bottom: 12px;

    }

    .contact-info-row{
        margin-left: 12px;
    }
}