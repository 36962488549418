@font-face {
    font-family: 'Inter-Regular';
    src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Bold';
    src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Light';
    src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
    
  }


.services-main{
    width: 100%;
    margin-top: 192px;
}

.services-wrapper{
    width: 80%;
    margin: 0 auto;
    display: flex;
}

.services-column1{
    width: 50%;
}

.services-column2{
    width: 50%;
    margin-top: 20%;
}

.service-cell{
    width: 80%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 121px;
}

.service-img{
    width: 100%;
}

.service-paragraph{
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(2rem, 1.7345rem + 1.4159vw, 4rem);
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 12px;
}

.services-link{
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #8E8CF2;
    font-size: clamp(1rem, 0.9336rem + 0.354vw, 1.5rem);
        margin: 0px;
}
    

.more-div{
    display: flex;
}

.arrow-margin{
    margin-left: 12px;
    transition: margin 0.3s ease;
}

.more-div{
    cursor: pointer;
}

.more-div:hover .arrow-margin{
    margin-left: 24px;
}

.super-button{
    font-size: 16px;
    
    width: 240px;
    height: 50px;
    border-radius: 50px;
    background-color: black;
    color: rgb(4, 8, 23);
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    border: 1px solid #8E8CF2;
    cursor: pointer;
    transition: box-shadow 0.6s ease;
    color: #EEEEEE;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%) translateY(-70px);
}



/*.super-button:hover{
    background-color: #8E8CF2;
    color: black;
}*/

.super-button:hover {
    box-shadow: inset 19.5em 0 0 0 #8E8CF2;
    color: black;
    border: 1px solid #8E8CF2;

}

@media screen and (max-width: 1400px) {
    .services-wrapper{
        width: 90%;
        margin: 0 auto;
        display: flex;
    }
}

@media screen and (max-width: 1000px) {
    .services-wrapper{
        width: 100%;
        margin: 0 auto;
        display: flex;
    }

    .service-cell{
        width: 80%;
        margin: 0 auto;
        position: relative;
        margin-bottom: 80px;
    }

    .services-main{
        width: 100%;
        margin-top: 121px;
    }

}

@media screen and (max-width: 700px) {
    .services-wrapper{
        flex-direction: column;
    }

    .services-column1{
        width: 100%;
    }

    .services-column2{
        margin-top: 0%;
        width: 100%;

    }

    .service-cell{
        width: 80%;
        margin: 0 auto;
        position: relative;
        margin-bottom: 64px;
    }

    .services-main{
        width: 100%;
        margin-top: 80px;
    }

    .super-button{
        
        position: absolute;
        left: 50%;
        transform: translate(-50%) translateY(-32px);
    }
}