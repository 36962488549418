@font-face {
    font-family: 'Inter-Regular';
    src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Bold';
    src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Light';
    src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
    
  }


.faq-main{
    width: 100%;
    margin-top: 121px;
    margin-bottom: 200px;
}

.faq-wrapper{
    width: 60%;
    margin: 0 auto;
}

.faq {
    position: relative;
    width: 100%;
    height: 80px;
    cursor: pointer;
    transition: height 0.3s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    
}


.faq::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.01em; /* Adjust the height to make it thinner */
    background-color: rgba(255,255,255,0.25);
}



.faq-h{
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(1rem, 0.8673rem + 0.708vw, 2rem);
        margin: 0;
    margin-top: 10px;
}

.thefqwrapper{
    
}
.faq-p{
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(0.75rem, 0.6836rem + 0.354vw, 1.25rem);   opacity: 0;
    transition: opacity 0.3s ease;
}

.faq:hover{
    height: 180px;

}


.faq:hover .faq-p{
opacity: 1;
}

@media screen and (max-width: 1500px) {
    .faq-wrapper{
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1100px) {
    .faq-wrapper{
        width: 80%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 900px) {
    .faq-wrapper{
        width: 90%;
        margin: 0 auto;
    }
}