@font-face {
  font-family: 'Inter-Regular';
  src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
  
}

.navbar-main{
    width: 100%;
    height: 80px;
    background-color: black;
    z-index: 90;
    position: fixed; /* or sticky, depending on your design */
  top: 0;
  left: 0;
  
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Horizontally center the items */
  align-items: center; /* Vertically center the items */
}

.navbar-layout{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.navbar-company-name{
  color: #EEEEEE;
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  cursor: pointer;
  height: 32px;
  width: 75px;
}

.logo-img{
  width: 100%;
}

.navbar-company-service{
  color: #EEEEEE;
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin-left: 10px;
  margin-top: 22px;
}

.navbar-navigation-links{
  display: flex;
  flex-direction: row;
  margin-left: auto;

}

.navbar-link{
  color: #EEEEEE;
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: 50px;
}

.navbar-link:hover{
  color: #8E8CF2;
}

.flag-circle:hover{
  transform: scale(1.15);

}

.green-link{
  color: #8E8CF2;
}

.green-link:hover{
  color: #EEEEEE;
}

.navbar-menu-link{
  color: #EEEEEE;
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #EEEEEE;
  transition: width 0.3s ease-in-out;
  background-color: #EEEEEE;
  margin-bottom: 6px;
}

.line:first-child,
.line:last-child {
width: 32.5px;
}

.line:nth-child(2) {
width: 40px;

}

.menu-icon:hover .line:first-child,
.menu-icon:hover .line:last-child {
width: 40px;
}

.menu-icon:hover .line:nth-child(2) {
width: 32.5px;
}

.menu {
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  border-radius: 0px;
  color: #EEEEEE;
  width: 100%;
  height: 0px;
  overflow: hidden;
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  opacity: 0;
  transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out;
}

.menu.open {
  opacity: 1;
  height: 300px;
}


.navbar-link-open{
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3px;
  font-size: 24px;
  cursor: pointer;
}

.flag-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.flag-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.flag-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flag-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #EEEEEE;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.flag-link.open .flag-dropdown {
  display: block;
}

.flag-option {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.flag-option:hover {
  background-color: #f1f1f1;
}

.flag-option img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.flag-option.active {
  background-color: #8E8CF2;
}

.flag-margin{
  margin-top: 20px;
}

a {
  text-decoration: none;
  transition: color 0.3s;
  outline: none;
  color: #EEEEEE;
}

.contact-animate{
  color: #8E8CF2;

}

a:hover,
a:focus {
  color: #8E8CF2;
}

a.flip-animate {
  perspective: 1000px;
  
  span {
    position: relative;
    display: inline-block;
    padding: 0;    
    transition: transform 0.3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
  }
  
  span:before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    content: attr(data-hover);
    transition: color 0.3s;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    text-align: center;
  }
  
  &:hover span,
  &:focus span {
    transform: rotateX(90deg) translateY(-22px);
  }
  
  &:hover span:before,
  &:focus span:before {
    color: #8E8CF2;
  }
}

a.flip-animate2 {
  perspective: 1000px;
  
  span {
    position: relative;
    display: inline-block;
    padding: 0;    
    transition: transform 0.3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
  }
  
  span:before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    content: attr(data-hover);
    transition: color 0.3s;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    text-align: center;
  }
  
  &:hover span,
  &:focus span {
    transform: rotateX(90deg) translateY(-22px);
  }
  
  &:hover span:before,
  &:focus span:before {
    color: #EEEEEE;
  }
}


@media (max-width: 1000px) {
  .navbar-link {
    display: none;
  }

  /* Show the "MENU" link only when screen width is less than 1000px */
  .navbar-navigation-links .navbar-menu-link {
    display: flex;
    align-items: center;
    color: #EEEEEE;
    font-family: 'Inter-Regular';
    font-style: normal;
  font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  .navbar-company-name{
    color: #EEEEEE;
    font-family: 'Inter-Bold';
    font-style: normal;
  font-weight: 400;
    font-size: 32px;
    cursor: pointer;
    height: 32px;
    width: 75px;
  }

  .logo-img{
    width: 100%;
  }
  
  .navbar-company-service{
    color: #EEEEEE;
    font-family: 'Inter-Light';
    font-style: normal;
  font-weight: 400;
    font-size: 10px;
    margin-left: 10px;
    margin-top: 22px;
  }

  .flag-circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
}

@media (min-width: 1001px) {
  .navbar-menu-link {
    display: none;
  }

  

  
}