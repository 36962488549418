@font-face {
  font-family: 'Inter-Regular';
  src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
  
}


.loading-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    display: flex;
    flex-direction: column;
}

.move-up {
  animation: moveUpAnimation 1s ease forwards; /* Animation name, duration, timing function, and fill mode */
}

@keyframes moveUpAnimation {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

.loading-screen-content {
    background-color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: white;
    font-family: 'Inter-Light', sans-serif;
    font-style: normal;
  font-weight: 400;
  }

.loading-circle {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid #8E8CF2; /* Change to your desired color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



.progress-bar {
  position: absolute;
  top: 50%;
  height: 2px;
  background: #8E8CF2;
}

.done {
  top: 0;
  height: 100%;
  width: 100%;
  transition: all .33s ease;
}

.count {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-weight: 100;
  font-size: 3em;
  margin-top: -1.33em;
  color: #8E8CF2;
  font-family: 'Inter-Light', sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .move-up {
    animation: moveUpAnimation 2s ease forwards; /* Animation name, duration, timing function, and fill mode */
  }
}