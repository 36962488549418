@font-face {
    font-family: 'Inter-Regular';
    src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Bold';
    src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Light';
    src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
    
  }

.story-main{
    width: 100%;
    margin-top: 196px;
}

.story-wrapper{
    width: 70%;
    margin: 0 auto;
    display: flex;
}

.image-column{
    width: 40%;
    position: relative;
}

.story-column{
    width: 60%;
}

.story-div{
    padding: 60px;
    background-color: white;
    margin-top: 48px;
}

.story-img{
    width: 100%;
    margin-top: 25%;
}

.story-header{
    color: #042C53;
    font-family: 'Inter-Bold', sans-serif;
    font-style: normal;
  font-weight: 400;
    font-size: clamp(1.5rem, 1.3673rem + 0.708vw, 2.5rem);
}

.story-subheader{
    color: #8E8CF2;
    font-size: clamp(0.875rem, 0.792rem + 0.4425vw, 1.5rem);
    font-family: 'Inter-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.story-paragraph{
    color: black;
    font-size: clamp(0.75rem, 0.6836rem + 0.354vw, 1.25rem);
    font-family: 'Inter-Light', sans-serif;
    font-style: normal;
  font-weight: 400;
}

.stats-row{
    display: flex;
    margin-top: 32px;
    justify-content: space-around; /* or space-between based on your preference */

}

.stats-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.story-numbers{
    color: #042C53;
    font-family: 'Inter-Bold', sans-serif;
    font-size: clamp(1.5rem, 1.3673rem + 0.708vw, 2.5rem);
    margin-bottom: 10px;

}

.plus{
    color: #042C53;
    font-family: 'Inter-Bold', sans-serif;
    font-style: normal;
  font-weight: 400;
    font-size: clamp(1.5rem, 1.3673rem + 0.708vw, 2.5rem);
    margin-bottom: 10px;
}

.story-numbers-row{
    color: #042C53;
    font-family: 'Inter-Bold', sans-serif;
    font-style: normal;
  font-weight: 400;
    font-size: clamp(1.5rem, 1.3673rem + 0.708vw, 2.5rem);
}

.numbers-paragraph{
    color: black;
    font-family: 'Inter-Bold', sans-serif;
    font-style: normal;
  font-weight: 400;
    font-size: clamp(0.375rem, 0.292rem + 0.4425vw, 1rem);
}

.fence-img{
    position: absolute;
    top: -10%;
    left: -15%;
    width: 50%;
}

.story-numbers-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1000px) {

    .story-main{
        width: 100%;
        margin-top: 64px;
    }

    .story-wrapper{
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .image-column{
        width: 100%;
        position: relative;
    }
    
    .story-column{
        width: 100%;
    }

    .fence-img{
        display: block;
    }

    .story-div{
        padding: 20px;
        margin-top: 0px;
    }
}