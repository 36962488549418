@font-face {
    font-family: 'Inter-Regular';
    src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');

}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');

}

@font-face {
    font-family: 'Inter-Light';
    src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');

}

@font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');

}


.tribulet-main {
    width: 100%;
    margin-top: 80px;
}

.tribulat-wrapper {
    width: 80%;
    margin: 0 auto;
}

.tribulet-row {
    display: flex;
    width: 100%;
}

.tribulet {
    width: 33.33%;
    padding: 30px
}

.icon-row {
    display: flex;
    align-items: center;
}

.icon-circle {
    width: 50px;
    /* Set your desired width */
    height: 50px;
    /* Set your desired height */
    background-color: #8E8CF2;
    /* Set your desired background color */
    border-radius: 50%;
    /* Make it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-img {
    width: 40%;
    /* Set your desired width */
    height: auto;
    /* Maintain aspect ratio */
}

.tribulet-heade {
    font-size: clamp(1rem, 0.9336rem + 0.354vw, 1.5rem);
    font-family: 'Inter-Bold', sans-serif;
    font-style: normal;
  font-weight: 400;
    color: #EEEEEE;
    margin-left: 16px;
}

.tribulet-paragraph {
    font-size: clamp(0.875rem, 0.8252rem + 0.2655vw, 1.25rem);
    font-family: 'Inter-Light', sans-serif;
    font-style: normal;
  font-weight: 400;
    color: #EEEEEE;
}

@media (max-width: 1250px) {
    .tribulet-row {
        flex-direction: column;
    }

    .tribulat-wrapper {
        width: 90%;
        margin: 0 auto;
    }

    .tribulet {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}