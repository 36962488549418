@font-face {
  font-family: 'Inter-Regular';
  src: url('./Resources/Fonts/Inter-Regular.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./Resources/Fonts/Inter-Bold.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-Light';
  src: url('./Resources/Fonts/Inter-Light.ttf') format('woff2');
  
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('./Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
  
}

body {
  margin: 0;
  font-family: 'Inter-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;

}

code {
  font-family: 'Inter-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(54, 55, 60);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(24,25,30);
}