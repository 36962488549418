@font-face {
    font-family: 'Inter-Regular';
    src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Bold';
    src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Light';
    src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
    
  }

.buildings-main {
    width: 100%;
    margin-top: 80px;
}

.buildings-wrapper-desktop {
    width: 100%;
    height: 610px;
    background-size: cover;
    background-image: url('../../Resources/Images/webp1.webp');

    background-position: center;
    display: flex;
    transition: background-image 0.3s ease;
    /* Add a smooth transition effect */

}

.buildings-cell {
    width: 33.33%;
    height: 100%;
    border-right: 1px solid white;
    /* Add right border with white color */
    position: relative;
}

.buildings-cell:last-child {
    border-right: none;
}

.buildings-wrapper-desktop.new-bg {
    background-image: url('../../Resources/Images/webp2.webp');
}

.buildings-wrapper-desktop.new-bg2 {
    background-image: url('../../Resources/Images/webp3.webp');
}

.cell-paragraph {
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: white;
    position: absolute;
    bottom: 2%;
    left: 5%;
    transition: bottom 0.3s ease;
    /* Add a smooth transition effect */

}

.buildings-cell:hover .cell-paragraph {
    bottom: 5%;

}

.buildings-wrapper-mobile {
    display: none;
}

@media screen and (max-width: 1000px) {
    .buildings-wrapper-desktop {
        display: none;
    }

    .buildings-wrapper-mobile {
        display: block;
    }

    .building-cell-mobile {
        width: 100%;
        height: 141px;
        border-bottom: 1px solid white;
        /* Add right border with white color */
        position: relative;
    }



    .building-cell-mobile:last-child {
        border-bottom: none;
    }

    .bg1 {
        background-size: cover;
        background-image: url('../../Resources/Images/webp1.webp');

        background-position: center;
    }

    .bg2 {
        background-size: cover;
        background-image: url('../../Resources/Images/webp2.webp');

        background-position: center;
    }

    .bg3 {
        background-size: cover;
        background-image: url('../../Resources/Images/webp3.webp');

        background-position: center;
    }

    .cell-paragraph{
        font-size: 20px;
        left: 2%;
        bottom: 0%;
    }
}