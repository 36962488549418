@font-face {
    font-family: 'Inter-Regular';
    src: url('../../Resources/Fonts/Inter-Regular.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Bold';
    src: url('../../Resources/Fonts/Inter-Bold.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-Light';
    src: url('../../Resources/Fonts/Inter-Light.ttf') format('woff2');
    
  }
  
  @font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../../Resources/Fonts/Inter-ExtraLight.ttf') format('woff2');
    
  }

.about-main{
    width: 100%;
    margin-top: 240px;
    overflow: hidden;
}

.about-wrapper{
    width: 80%;
    margin: 0 auto;
}

.about-us-header{
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(2rem, 1.7345rem + 1.4159vw, 4rem);
    text-align: center;
}

.zgodba{
    color: #8E8CF2;
}

.zgodba-paragraph{
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(1rem, 0.359rem + 1.0256vw, 2rem);
    text-align: center;
    width: 80%;
    margin: 0 auto;
}

.second-wrapper{
    width: 100%;
    display: flex;
    margin-top: 240px;
}

.second-left{
    width: 40%;
    margin-left: 10%;
}

.second-right{
    width: 50%;
}

.about-zacetek{
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    font-size: clamp(1.5rem, 0.5385rem + 1.5385vw, 3rem);
        
}

.zacetek-pa{
    font-size: clamp(1rem, 0.6795rem + 0.5128vw, 1.5rem);
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
}

.zacetek-pp{
    font-size: clamp(1rem, 0.6795rem + 0.5128vw, 1.5rem);
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    margin-bottom: 40px;
}

.zacetek-ph{
    font-size: clamp(1rem, 0.6795rem + 0.5128vw, 1.5rem);
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 400;
    color: #EEEEEE;
    margin-top: 40px;
    margin-bottom: 40px;

}

.second-right{
    position: relative;
}

.second-left-hidden{
    display: none;
}

.gsap-fence{
    width: 200px;
    position: absolute;
    left: 10%;
    z-index: 1;
    top: -30%;
}

.interior-img{
    width: 60%;
    position: absolute;
    left: 50%;
    transform: translate(-0%,-50%);
}

.storyy-img{
    width: 50%;
    position: absolute;
    left: 20%;
    top: 20%;
}

.third-wrapper{
    width: 100%;
    margin-top: 120px;
    display: flex;
    margin-bottom: 64px;
}

.third-left{
    width: 50%;
    position: relative;
}

.third-right{
    width: 40%;
}

.team-slika{
    width: 70%;
    position: absolute;
    right: 10%;
    top: 30%;
}

.razlogi-header{
    margin-top: 121px;
    transform: translateY(121px);
    width: 90%;
    margin: 0 auto;
}

.zdaj-button{
    margin-top: 80px;
}

@media screen and (max-width: 1600px) {
    .storyy-img{
        width: 50%;
        position: absolute;
        left: 20%;
        top: 10%;
        margin-top: 10%;
    }

    .interior-img{
        width: 60%;
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-0%,-50%);
    }
}

@media screen and (max-width: 1300px) {
    .razlogi-header{
       

        margin-top: 64px;
        transform: translateY(64px);
    }

    .second-wrapper{
        width: 100%;
        display: flex;
        margin-top: 220px;
    }
    
}

@media screen and (max-width: 1000px) {
    .about-wrapper{
        width: 90%;
        margin: 0 auto;
    }
    .second-wrapper{
        flex-direction: column;
        margin-top: 320px;

    }

    .second-left{
        display: none;
    }

    .second-left-hidden{
        display: block;
    }

    .second-right{
        width: 100%;
        margin-top: -100px;
        margin-bottom: -100px;
    }

    .third-wrapper{
        flex-direction: column;
        margin-top: 121px;
        margin-bottom: -40px;
    }

    .third-left{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }

    .third-right{
        width: 100%;
    }

    .team-slika{
        position:relative;
        margin: 0 auto;
        width: 100%;
        left: 0%;
        transform: translateY(-10%) !important;
    }

    .interior-img{
        position: relative;
        transform: translateX(-15%) !important;
    }

    .storyy-img{
        position: relative;
        left: 0%;
        width: 100%;
        top: 10%;
    }

    
    

}



@media screen and (max-width: 650px) {
    .second-wrapper{
        flex-direction: column;
        margin-top: 200px;

    }
    .second-right{
        width: 100%;
        margin-top: -120px;
        margin-bottom: -40px;
    }

    

    .storyy-img{
        position: relative;
        left: 0%;
        width: 100%;
        top: 10%;
        margin-top: 30%;
    }

    .third-left{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
        margin-top: 12px;
    }

    .third-wrapper{
        margin-top: 70px;
    }
}

@media screen and (min-width: 1921px) {
    .third-wrapper{
        margin-top: 240px;
        margin-bottom: 220px;
    }

    .razlogi-header{
       

        margin-top: 121px;
        transform: translateY(64px);
    }

    .storyy-img{
        width: 50%;
        position: absolute;
        left: 20%;
        top: 10%;
    }
}

@media screen and (min-width: 2921px) {
    .about-wrapper{
        width: 60%;
    }
}


.tmd-div{
    height: 320px;
}

